<template>
  <p-form
    @submit="onSubmit"
    v-slot="{ invalid }"
  >
    <div class="c-row c-horizontal-center">
      <div class="c-col-2 sm:c-col-6 md:c-col-12 lg:c-col-10">
        <!-- Título "Dados Pessoais" e subtítulo -->
        <div class="c-row ui-mb-4">
          <div class="c-col-full">
            <!-- Título -->
            <div class="c-text-h3">
              Dados pessoais
            </div>

            <!-- Subtítulo -->
            <div class="c-text-b2 c-text-gray">
              *Preenchimento obrigatório
            </div>
          </div>
        </div>

        <!-- Nome completo -->
        <div class="c-row ui-mb-4">
          <validation-provider
            class="c-col-full sm:c-col ui-mb-4 sm:ui-mb-0"
            rules="required|full_name"
            name="Nome completo"
            v-slot="{ errors }"
          >
            <p-input
              data-cy="nameField"
              key="nameField"
              label="Nome completo*"
              name="name"
              v-model.trim="user.name"
              :error-message="errors[0]"
            />
          </validation-provider>

          <!-- E-mail -->
          <div class="c-col-full sm:c-col">
            <p-input
              data-cy="emailField"
              key="emailField"
              label="Email*"
              name="email"
              v-model.trim="user.email"
              readonly
              icon="lock"
              disabled
            />
          </div>
        </div>

        <!-- CPF e Telefone -->
        <div class="c-row ui-mb-4">
          <!-- CPF -->
          <div class="c-col-full sm:c-col">
            <p-input
              data-cy="cpfField"
              key="cpfField"
              label="CPF*"
              name="cpf"
              v-model="user.cpf"
              mask="000.000.000-00"
              hint="Somente números"
              icon="lock"
              icon-info="info"
              disabled
            />
          </div>

          <!-- Telefone -->
          <validation-provider
            class="c-col-full sm:c-col"
            rules="required|length:11"
            name="Celular"
            v-slot="{ errors }"
            :custom-messages="{ length: 'Celular inválido' }"
          >
            <p-input
              data-cy="phoneField"
              key="phoneField"
              label="Celular*"
              name="phone"
              v-model="user.phone"
              hint="DDD + nº do celular"
              icon-info="info"
              :error-message="errors[0]"
              mask="(00) 0 0000-0000"
            />
          </validation-provider>

          <!-- Data de nascimento -->
          <validation-provider
            class="c-col-full sm:c-col"
            :rules="{ required: true, after: dateMinusYear(120), before: dateMinusYear(0) }"
            name="Data de nascimento"
            v-slot="{ errors }"
          >
            <p-input
              type="date"
              data-cy="birthdayField"
              key="birthdayField"
              label="Data de nascimento*"
              name="birthday"
              v-model="user.birthday"
              hint="Data de nascimento"
              icon-info="info"
              icon="calendar"
              :error-message="errors[0]"
            />
          </validation-provider>
        </div>

        <separator />
      </div>
    </div>

    <!-- Botão Alterar e Texto Cancelar -->
    <div class="c-row c-horizontal-center">
      <!-- Botão Alterar -->
      <div class="c-col-full sm:c-col-1/4 lg:c-col-2">
        <c-button
          data-cy="updateSubmitButton"
          type="submit"
          :disabled="invalid"
          :loading="isSubmitting"
          class="ui-w-full ui-mb-4 sm:ui-mb-0"
        >
          Salvar alteração
        </c-button>
      </div>

      <!-- Botão Voltar -->
      <div class="c-col-full sm:c-col-1/4 lg:c-col-2">
        <router-link :to="{ name: 'meus-dados' }">
          <c-button
            outline
            :disabled="isSubmitting"
            class="ui-w-full"
          >
            Voltar sem salvar
          </c-button>
        </router-link>
      </div>
    </div>
  </p-form>
</template>

<script>
import { mapGetters } from 'vuex';
import { CButton } from '@estrategiahq/coruja-web-ui';
import { User } from '@/models/user';
import PForm from '@/components/p-form';
import PInput from '@/components/p-input';
import Separator from '@/components/separator';
import { UserStoreKeys } from '@/store/modules/user';
import { ValidationProvider } from 'vee-validate';
import { dateMinusYear } from '@/utils/date';

export default {
  components: {
    CButton,
    PForm,
    PInput,
    Separator,
    ValidationProvider,
  },
  data: () => ({
    user: new User(),
  }),
  async mounted() {
    await this.$store.dispatch(UserStoreKeys.ACTION_FETCH_LOGGED_USER);
    this.user = { ...this.loggedUser };
  },
  computed: {
    ...mapGetters({
      loggedUser: UserStoreKeys.GET_LOGGED_USER,
      isSubmitting: UserStoreKeys.GET_REQUEST_LOADING,
    }),
  },
  methods: {
    dateMinusYear(year) {
      return dateMinusYear(year);
    },
    async onSubmit() {
      await this.$store.dispatch(UserStoreKeys.ACTION_UPDATE_LOGGED_USER, this.user);
      if (this.$isPreview('fase2')) {
        // Se estiver em preview busca novamente os dados do user para passar pelo mock
        await this.$store.dispatch(UserStoreKeys.ACTION_FETCH_LOGGED_USER);
      }
      this.$router.push({ name: 'meus-dados' });
    },
  },
};
</script>
